import React, { useEffect, useState } from "react";
import WebHeader from "../common/WebHeader";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import Ship from "../../assets/svg/cursor Yellow.svg";
import Ship1 from "../../assets/svg/cursor Blue.svg";
import Ship2 from "../../assets/svg/cursor Green.svg";
import Ship3 from "../../assets/svg/cursor Pink.svg";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 20.5937, // Centered around India
  lng: 78.9629,
};

const mapOptions = {
  minZoom: 2,
  maxZoom: 10,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
    strictBounds: true,
  },
  mapTypeId: "terrain",
  noClear: true,
};

const AboutHero = () => {
  const [markers, setMarkers] = useState([]);
  const [selectedShip, setSelectedShip] = useState(null);
  const images = [Ship, Ship1, Ship2, Ship3];

  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  useEffect(() => {
    const fetchShips = async () => {
      try {
        const response = await axios({
          url: process.env.REACT_APP_URL + "/getVessels",
          method: "GET",
        });

        if (response.data.status == "success") {
          const shipsWithImages = response.data?.data?.data?.vessels.map(
            (coords, index) => ({
              latitude: coords.lat,
              longitude: coords.lon,
              mmsi: coords.mmsi,
              name: coords.name,
              course: coords.course,
              speed: coords.speed,
              imo: coords.imo,
              last_report: coords.last_position_UTC,
              destination: coords.destination,
              type: coords.type_specific,
              imageUrl: getRandomImage(),
            })
          );
          setMarkers(shipsWithImages);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchShips();
  }, []);

  const handleMarkerClick = async (ship) => {
    setSelectedShip(ship);
  };

  const handleCloseClick = () => {
    setSelectedShip(null);
  };

  return (
    <div className="hero_bg min-vh-100 d-flex flex-column">
      <div>
        <WebHeader />
      </div>
      <div className="d-flex map-container flex-grow-1 position-relative custom-scroll-bar">
        {selectedShip ? (
          <div class="container width-200 py-2 position-absolute top-0 h-100 overflow-auto left-0 z-index-1">
            <div class="card">
              <div class="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-1">
                  {/* <img
                    src="https://cdn.pixabay.com/photo/2016/08/24/17/07/india-1617463_1280.png"
                    alt="Flag"
                    style={{ width: "15%" }}
                  /> */}
                  <div>
                    <p class="ms-2 fs_13 fw-bold mb-0">
                      {selectedShip?.name || "--"}
                    </p>
                    <p class="ms-2 fs_13 mb-0">{selectedShip?.type || "--"}</p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedShip(null)}
                  type="button"
                  class="btn-close btn-close-white"
                  aria-label="Close"
                ></button>
              </div>
              {/* <img
              src="https://static.vesselfinder.net/ship-photo/9651876-566540000-db4401173289ee7d1bf5c0674cb4cae1/1"
              class="card-img-top vessel-image"
              alt="Vessel Image"
            /> */}
              <div class="card-body vessel-info">
                <div class="vessel-details">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            width: "88%",
                          }}
                          className="d-flex gap-4 "
                        >
                          <div className="">
                            <p className="mb-0 fs_13 fw-bold">Speed:</p>
                            <p className="mb-0 fs_13 fw-medium">
                              {selectedShip?.speed || "--"}
                            </p>
                          </div>
                          <div className="">
                            <p className="mb-0 fs_13 fw-bold">Course:</p>
                            <p className="mb-0 fs_13 fw-medium">
                              {selectedShip?.course || "--"}
                            </p>
                          </div>
                          <div className="">
                            <p className="mb-0 fs_13 fw-bold">Draught:</p>
                            <p className="mb-0 fs_13 fw-medium">
                              {selectedShip?.draught || "--"}
                            </p>
                          </div>
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            width: "88%",
                          }}
                          className="d-flex gap-4"
                        >
                          <div className="">
                            <p className="mb-0 fs_13 fw-bold">Status:</p>
                            <p className="mb-0 fs_13  fw-medium">At anchor</p>
                          </div>
                          <div className="d-flex flex-column ">
                            <p className="mb-0 fs_13 fw-bold">Last report:</p>
                            <p className="mb-0 fs_13  fw-medium">
                              {selectedShip?.last_report || "--"}
                            </p>
                          </div>
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            width: "88%",
                          }}
                          className="d-flex gap-4"
                        >
                          <div className="d-flex flex-column ">
                            <p className="mb-0 fs_13 fw-bold">Destination:</p>
                            <p className="mb-0 fs_13  fw-medium">
                              {selectedShip?.destination || "--"}
                            </p>
                          </div>
                          <div className="d-flex flex-column ">
                            <p className="mb-0 fs_13 fw-bold">
                              Destination Time:
                            </p>
                            <p className="mb-0 fw-medium fs_13">
                              {selectedShip?.destination_time || "--"}
                            </p>
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="d-flex gap-2 align-items-center">
                          <img
                            src="https://cdn.pixabay.com/photo/2016/08/24/17/07/india-1617463_1280.png"
                            alt="Flag"
                            style={{ width: "30px", height: "20px" }}
                          />
                          <div>
                            <p className="mb-0 fw-bold fs_13">
                              {selectedShip?.to || "--"}
                            </p>
                            <p className="mb-0 fw-medium fs_13  ">
                              {selectedShip?.toTime || "--"}
                            </p>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                <td className="d-flex  ms-auto">
                  <div className="col-4">
                    <p className="mb-0 fs_13 fw-bold">IMO:</p>
                    <p className="mb-0 fs_13 fw-medium">
                      {selectedShip?.imo || "--"}
                    </p>
                  </div>
                  <div className=" col-4">
                    <p className="mb-0 fs_13 fw-bold">MMSI: :</p>
                    <p className="mb-0 fs_13 fw-medium">{selectedShip?.mmsi}</p>
                  </div>
                </td>
              </div>
            </div>
          </div>
        ) : null}

        <div className="w-100">
          <LoadScript googleMapsApiKey="AIzaSyCK4eAIy9dTeiDKCbkqfQHomXlAVszyQlg">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={3}
              options={mapOptions}
            >
              {markers.map((ship, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: ship.latitude,
                    lng: ship.longitude,
                  }}
                  onClick={() => handleMarkerClick(ship)}
                  icon={{
                    url: ship.imageUrl,
                    scaledSize: window.google
                      ? new window.google.maps.Size(17, 17)
                      : null,
                  }}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
