import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <div className="bg_theme py-3">
        <Container>
          <h1 className=" text-white text-center fw-bold">Privacy Policy</h1>
        </Container>
      </div>
      <Container className="my-4">
        <h2 className="mt-5 color_orange fw-bold">About BituConnect</h2>
        <p className=" fs_17">
          BituConnect is an online platform that connects buyers and vendors for
          the purchase of bitumen, providing tools for requesting quotes,
          managing orders, and tracking shipments.
        </p>

        <h2 className="mt-5 color_orange fw-bold">How We Use Your Data</h2>
        <p className=" fs_17">
          We collect and use your data to ensure a seamless experience on
          BituConnect. The information allows us to assist you in requesting
          quotes, managing orders, and tracking shipments efficiently.
        </p>

        <h2 className="mt-5 color_orange fw-bold">Data Security</h2>
        <p className=" fs_17">
          Your data is securely stored and processed to ensure the highest
          levels of privacy. We utilize industry-standard encryption and
          security measures to protect your personal information from
          unauthorized access.
        </p>

        <h2 className="mt-5 color_orange fw-bold">Your Rights</h2>
        <p className=" fs_17">
          You have the right to access, modify, or request the deletion of your
          personal data. If you wish to exercise any of these rights, please
          contact our privacy team, and we will assist you in processing your
          request promptly.
        </p>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
