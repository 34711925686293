// // import React, { useState, useRef, useEffect } from "react";
// // import { Container } from "react-bootstrap";
// // import img from "../../assets/png/img.webp";
// // import img1 from "../../assets/png/img1.webp";
// // import img2 from "../../assets/png/img2.webp";
// // import img3 from "../../assets/png/img3.webp";

// // const FeaturesSection = () => {
// //   const [activeIndex, setActiveIndex] = useState(null);

// //   // Refs for each paragraph
// //   const paragraphRefs = [
// //     useRef(null),
// //     useRef(null),
// //     useRef(null),
// //     useRef(null),
// //     useRef(null),
// //   ];

// //   // Function to check which paragraph is in view
// //   const checkActiveParagraph = () => {
// //     const scrollTop = window.scrollY;
// //     const windowHeight = window.innerHeight;

// //     const index = paragraphRefs.findIndex((ref) => {
// //       const element = ref.current;
// //       if (element) {
// //         const rect = element.getBoundingClientRect();
// //         return (
// //           rect.top >= 0 &&
// //           rect.top < windowHeight / 2 &&
// //           rect.bottom > windowHeight / 2
// //         );
// //       }
// //       return false;
// //     });

// //     if (index !== -1) {
// //       setActiveIndex(index);
// //     }
// //   };

// //   useEffect(() => {
// //     // Add scroll event listener
// //     window.addEventListener("scroll", checkActiveParagraph);

// //     // Clean up the event listener on component unmount
// //     return () => {
// //       window.removeEventListener("scroll", checkActiveParagraph);
// //     };
// //   }, []);

// //   // Handle feature click and scroll to corresponding paragraph
// //   const handleFeatureClick = (index) => {
// //     setActiveIndex(index);

// //     // Use setTimeout to ensure state update is processed before scrolling
// //     setTimeout(() => {
// //       paragraphRefs[index].current.scrollIntoView({ behavior: "smooth" });
// //     }, 0);
// //   };

// //   return (
// //     <div className="py-5">
// //       <Container>
// //         <div className="row py-5">
// //           <div
// //             className="col-4"
// //             style={{
// //               position: "sticky",
// //               top: "30%",
// //               height: "100%",

// //               overflowY: "auto",
// //               paddingTop: "0",
// //             }}
// //           >
// //             <h2 className="manrope_FF mb-4">Key Features</h2>
// //             {[
// //               "Transparent and Secure Transactions",
// //               "Real-Time Cargo Tracking",
// //               "Comprehensive Document Management",
// //               "Seamless Communication",
// //               "24/7 Support",
// //             ].map((feature, index) => (
// //               <p
// //                 key={index}
// //                 className={`mb-3 cursor-pointer border-black manrope_FF px-3 py-2 transition-effect ${
// //                   activeIndex === index
// //                     ? "font-weight-bold bg_theme px-3 py-2 text-white"
// //                     : "font-weight-light"
// //                 }`}
// //                 onClick={() => handleFeatureClick(index)}
// //               >
// //                 {feature}
// //               </p>
// //             ))}
// //           </div>
// //           <div className="col-8">
// //             <div className="ps-5 d-flex gap-5 align-items-center">
// //               <div className="pt-4">
// //                 <h4 className="manrope_FF mb-3 fw-bold">
// //                   Transparent and Secure Transactions
// //                 </h4>
// //                 <p
// //                   className={`manrope_FF mb-0 fs-6 transition-effect ${
// //                     activeIndex === 0 ? "fw-semibold" : "fw-light"
// //                   }`}
// //                   ref={paragraphRefs[0]}
// //                 >
// //                   BituConnect ensures every transaction is handled securely,
// //                   with clear documentation and communication throughout the
// //                   process. Both buyers and vendors can rely on the platform for
// //                   a smooth and risk-free experience.
// //                 </p>
// //               </div>
// //               <img width={300} src={img} alt="img" />
// //             </div>
// //             <div className="mt-5 ps-5 d-flex gap-5 align-items-center">
// //               <div className="pt-4">
// //                 <h4 className="manrope_FF mb-3 fw-bold">
// //                   Real-Time Cargo Tracking
// //                 </h4>
// //                 <p
// //                   className={`manrope_FF mb-0  fs-6 transition-effect ${
// //                     activeIndex === 1 ? "fw-semibold" : "fw-light"
// //                   }`}
// //                   ref={paragraphRefs[1]}
// //                 >
// //                   Once an order is placed, buyers can track their shipments in
// //                   real time using the 'Track My Cargo' feature. Simply enter the
// //                   Bill of Lading (BL) number to monitor the progress and
// //                   location of your goods.
// //                 </p>
// //               </div>
// //               <img width={300} src={img1} alt="img" />
// //             </div>
// //             <div className="mt-5 d-flex gap-5 align-items-center ps-5">
// //               <div className="pt-4">
// //                 <h4 className="manrope_FF mb-3 fw-bold">
// //                   Comprehensive Document Management
// //                 </h4>
// //                 <p
// //                   className={`manrope_FF mb-0 fs-6 transition-effect ${
// //                     activeIndex === 2 ? "fw-semibold" : "fw-light"
// //                   }`}
// //                   ref={paragraphRefs[2]}
// //                 >
// //                   Our built-in document management system allows for easy
// //                   upload, organization, and storage of all essential trade
// //                   documents, including Commercial Invoices, shipping documents,
// //                   and payment details.
// //                 </p>
// //               </div>
// //               <img width={300} src={img2} alt="img" />
// //             </div>
// //             <div className="mt-5 ps-5 d-flex gap-5 align-items-center">
// //               <div className="pt-4">
// //                 <h4 className="manrope_FF mb-3 fw-bold">
// //                   Seamless Communication
// //                 </h4>
// //                 <p
// //                   className={`manrope_FF mb-0  fs-6 transition-effect ${
// //                     activeIndex === 3 ? "fw-semibold" : "fw-light"
// //                   }`}
// //                   ref={paragraphRefs[3]}
// //                 >
// //                   BituConnect keeps communication open and efficient. All email
// //                   exchanges between buyers and vendors must include
// //                   operations@bituconnect.com for accountability and
// //                   transparency, ensuring smooth negotiations and timely
// //                   responses.
// //                 </p>
// //               </div>
// //               <img width={300} src={img2} alt="img" />
// //             </div>
// //             <div className="mt-5 d-flex gap-5 align-items-center  ps-5">
// //               <div className="pt-4">
// //                 <h4 className="manrope_FF mb-3 fw-bold">24/7 Support</h4>
// //                 <p
// //                   className={`manrope_FF mb-0  fs-6 transition-effect ${
// //                     activeIndex === 4 ? "fw-semibold" : "fw-light"
// //                   }`}
// //                   ref={paragraphRefs[4]}
// //                 >
// //                   Our dedicated support team is available around the clock to
// //                   assist with any issues you may encounter. You can reach out to
// //                   us via email at support@bituconnect.com or by submitting a
// //                   support ticket for quick resolutions.
// //                 </p>
// //               </div>
// //               <img width={300} src={img1} alt="img" />
// //             </div>
// //           </div>
// //         </div>
// //       </Container>
// //     </div>
// //   );
// // };

// // export default FeaturesSection;

// import React, { useState, useRef, useEffect } from "react";
// import { Container } from "react-bootstrap";
// import img from "../../assets/png/img.webp";
// import img1 from "../../assets/png/img1.webp";
// import img2 from "../../assets/png/img2.webp";
// import img3 from "../../assets/png/img3.webp";

// const FeaturesSection = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   // Refs for each paragraph
//   const paragraphRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null),
//   ];

//   // Function to check which paragraph is in view
//   const checkActiveParagraph = () => {
//     const scrollTop = window.scrollY;
//     const windowHeight = window.innerHeight;

//     const index = paragraphRefs.findIndex((ref) => {
//       const element = ref.current;
//       if (element) {
//         const rect = element.getBoundingClientRect();
//         return (
//           rect.top >= 0 &&
//           rect.top < windowHeight / 2 &&
//           rect.bottom > windowHeight / 2
//         );
//       }
//       return false;
//     });

//     if (index !== -1) {
//       setActiveIndex(index);
//     }
//   };

//   useEffect(() => {
//     // Add scroll event listener
//     window.addEventListener("scroll", checkActiveParagraph);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener("scroll", checkActiveParagraph);
//     };
//   }, []);

//   // Handle feature click and scroll to corresponding paragraph
//   const handleFeatureClick = (index) => {
//     // Scroll to the first feature first
//     window.scrollTo({ top: 0, behavior: "smooth" });

//     // Then scroll to the corresponding feature after a slight delay
//     setTimeout(() => {
//       setActiveIndex(index); // Set the active index after the scroll to the first div
//       const element = paragraphRefs[index].current;
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth", block: "start" });
//       }
//     }, 500); // Adjust the delay based on the time it takes to scroll to the top
//   };
//   return (
//     <div className="py-5">
//       <Container>
//         <div className="row py-5">
//           <div
//             className="col-4"
//             style={{
//               position: "sticky",
//               top: "30%",
//               height: "100%",
//               overflowY: "auto",
//               paddingTop: "0",
//             }}
//           >
//             <h2 className="manrope_FF mb-4">Key Features</h2>
//             {[
//               "Transparent and Secure Transactions",
//               "Real-Time Cargo Tracking",
//               "Comprehensive Document Management",
//               "Seamless Communication",
//               "24/7 Support",
//             ].map((feature, index) => (
//               <p
//                 key={index}
//                 className={`mb-3 cursor-pointer border-black manrope_FF px-3 py-2 transition-effect ${
//                   activeIndex === index
//                     ? "font-weight-bold bg_theme px-3 py-2 text-white"
//                     : "font-weight-light"
//                 }`}
//                 onClick={() => handleFeatureClick(index)}
//               >
//                 {feature}
//               </p>
//             ))}
//           </div>
//           <div className="col-8">
//             <div className="ps-5 d-flex gap-5 align-items-center">
//               <div className="pt-4">
//                 <h4 className="manrope_FF mb-3 fw-bold">
//                   Transparent and Secure Transactions
//                 </h4>
//                 <p
//                   className={`manrope_FF mb-0 fs-6 transition-effect ${
//                     activeIndex === 0 ? "fw-semibold" : "fw-light"
//                   }`}
//                   ref={paragraphRefs[0]}
//                 >
//                   BituConnect ensures every transaction is handled securely,
//                   with clear documentation and communication throughout the
//                   process. Both buyers and vendors can rely on the platform for
//                   a smooth and risk-free experience.
//                 </p>
//               </div>
//               <img width={300} src={img} alt="img" />
//             </div>
//             <div className="mt-5 ps-5 d-flex gap-5 align-items-center">
//               <div className="pt-4">
//                 <h4 className="manrope_FF mb-3 fw-bold">
//                   Real-Time Cargo Tracking
//                 </h4>
//                 <p
//                   className={`manrope_FF mb-0  fs-6 transition-effect ${
//                     activeIndex === 1 ? "fw-semibold" : "fw-light"
//                   }`}
//                   ref={paragraphRefs[1]}
//                 >
//                   Once an order is placed, buyers can track their shipments in
//                   real time using the 'Track My Cargo' feature. Simply enter the
//                   Bill of Lading (BL) number to monitor the progress and
//                   location of your goods.
//                 </p>
//               </div>
//               <img width={300} src={img1} alt="img" />
//             </div>
//             <div className="mt-5 d-flex gap-5 align-items-center ps-5">
//               <div className="pt-4">
//                 <h4 className="manrope_FF mb-3 fw-bold">
//                   Comprehensive Document Management
//                 </h4>
//                 <p
//                   className={`manrope_FF mb-0 fs-6 transition-effect ${
//                     activeIndex === 2 ? "fw-semibold" : "fw-light"
//                   }`}
//                   ref={paragraphRefs[2]}
//                 >
//                   Our built-in document management system allows for easy
//                   upload, organization, and storage of all essential trade
//                   documents, including Commercial Invoices, shipping documents,
//                   and payment details.
//                 </p>
//               </div>
//               <img width={300} src={img2} alt="img" />
//             </div>
//             <div className="mt-5 ps-5 d-flex gap-5 align-items-center">
//               <div className="pt-4">
//                 <h4 className="manrope_FF mb-3 fw-bold">
//                   Seamless Communication
//                 </h4>
//                 <p
//                   className={`manrope_FF mb-0  fs-6 transition-effect ${
//                     activeIndex === 3 ? "fw-semibold" : "fw-light"
//                   }`}
//                   ref={paragraphRefs[3]}
//                 >
//                   BituConnect keeps communication open and efficient. All email
//                   exchanges between buyers and vendors must include
//                   operations@bituconnect.com for accountability and
//                   transparency, ensuring smooth negotiations and timely
//                   responses.
//                 </p>
//               </div>
//               <img width={300} src={img2} alt="img" />
//             </div>
//             <div className="mt-5 d-flex gap-5 align-items-center  ps-5">
//               <div className="pt-4">
//                 <h4 className="manrope_FF mb-3 fw-bold">24/7 Support</h4>
//                 <p
//                   className={`manrope_FF mb-0  fs-6 transition-effect ${
//                     activeIndex === 4 ? "fw-semibold" : "fw-light"
//                   }`}
//                   ref={paragraphRefs[4]}
//                 >
//                   Our dedicated support team is available around the clock to
//                   assist with any issues you may encounter. You can reach out to
//                   us via email at support@bituconnect.com or by submitting a
//                   support ticket for quick resolutions.
//                 </p>
//               </div>
//               <img width={300} src={img1} alt="img" />
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default FeaturesSection;
import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import img from "../../assets/png/img.webp";
import img1 from "../../assets/png/img1.webp";
import img2 from "../../assets/png/img2.webp";
import img3 from "../../assets/png/img3.webp";

const FeaturesSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleScroll = () => {
    if (isClicked) return; // Prevent scroll effect while clicking
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    sectionRefs.forEach((ref, index) => {
      const element = ref.current;
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          setActiveIndex(index);
        }
      }
    });
  };

  const handleFeatureClick = (index) => {
    setActiveIndex(index);
    setIsClicked(true);
    sectionRefs[index].current.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      setIsClicked(false); // Re-enable scroll tracking after a delay
    }, 1000); // Delay in milliseconds (adjust as necessary)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isClicked]);

  return (
    <div className="py-5">
      <Container>
        <div className="row  d-lg-flex d-none py-5">
          <div
            className="col-4"
            style={{
              position: "sticky",
              top: "20%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h2 className="manrope_FF mb-4">Key Features</h2>
            {[
              "Transparent and Secure Transactions",
              "Real-Time Cargo Tracking",
              "Comprehensive Document Management",
              "Seamless Communication",
              "24/7 Support",
            ].map((feature, index) => (
              <p
                key={index}
                className={`mb-3 cursor-pointer manrope_FF px-3 py-2 transition-effect ${
                  activeIndex === index
                    ? "font-weight-bold bg_theme text-white"
                    : "font-weight-light"
                }`}
                onClick={() => handleFeatureClick(index)}
              >
                {feature}
              </p>
            ))}
          </div>
          <div className="col-8">
            {[
              {
                title: "Transparent and Secure Transactions",
                content:
                  "BituConnect ensures every transaction is handled securely, with clear documentation and communication throughout the process.",
                imgSrc: img,
              },
              {
                title: "Real-Time Cargo Tracking",
                content:
                  "Once an order is placed, buyers can track their shipments in real-time using the 'Track My Cargo' feature.",
                imgSrc: img1,
              },
              {
                title: "Comprehensive Document Management",
                content:
                  "Our built-in document management system allows for easy upload, organization, and storage of all essential trade documents.",
                imgSrc: img2,
              },
              {
                title: "Seamless Communication",
                content:
                  "BituConnect keeps communication open and efficient. All email exchanges between buyers and vendors must include operations@bituconnect.com.",
                imgSrc: img3,
              },
              {
                title: "24/7 Support",
                content:
                  "Our dedicated support team is available around the clock to assist with any issues you may encounter.",
                imgSrc: img1,
              },
            ].map((section, index) => (
              <div
                key={index}
                className="d-flex gap-5 align-items-center mb-5"
                ref={sectionRefs[index]}
              >
                <div>
                  <h4 className="manrope_FF mb-3 fw-bold transition-effect">
                    {section.title}
                  </h4>
                  <p
                    className={`manrope_FF mb-0 fs-6 ${
                      activeIndex === index ? "fw-bold" : "fw-light"
                    }`}
                  >
                    {section.content}
                  </p>
                </div>
                <img width={300} src={section.imgSrc} alt={section.title} />
              </div>
            ))}
          </div>
        </div>
        <div className=" d-lg-none d-block">
          {[
            {
              title: "Transparent and Secure Transactions",
              content:
                "BituConnect ensures every transaction is handled securely, with clear documentation and communication throughout the process.",
              imgSrc: img,
            },
            {
              title: "Real-Time Cargo Tracking",
              content:
                "Once an order is placed, buyers can track their shipments in real-time using the 'Track My Cargo' feature.",
              imgSrc: img1,
            },
            {
              title: "Comprehensive Document Management",
              content:
                "Our built-in document management system allows for easy upload, organization, and storage of all essential trade documents.",
              imgSrc: img2,
            },
            {
              title: "Seamless Communication",
              content:
                "BituConnect keeps communication open and efficient. All email exchanges between buyers and vendors must include operations@bituconnect.com.",
              imgSrc: img3,
            },
            {
              title: "24/7 Support",
              content:
                "Our dedicated support team is available around the clock to assist with any issues you may encounter.",
              imgSrc: img1,
            },
          ].map((section, index) => (
            <div
              key={index}
              className="d-sm-flex gap-5 align-items-center mb-5"
            >
              <div>
                <h4 className="manrope_FF mb-3 fw-bold transition-effect">
                  {section.title}
                </h4>
                <p className="manrope_FF mb-0 fs-6 mb-2">{section.content}</p>
              </div>
              <img width={300} src={section.imgSrc} alt={section.title} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FeaturesSection;
