import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import about_image from "../../assets/png/about_image.svg";
import about_image1 from "../../assets/png/about_image1.png";
import border_right_icon from "../../assets/png/Border_right_icon.svg";
import "../../components/homepage/home.css";

const HomeAboutUsComponent = () => {
  const [activeTab, setActiveTab] = useState("history");

  return (
    <div id="ourMission" className="py-5 mb-5">
      <Container>
        <Row>
          <Col md={6}>
            <p className="mb-0 manrope_FF color_orange fs_16">About us</p>
            <h5 className="fs_47 mt-3 mb-0 manrope_FF fw-bold">
              World Leading Bituconnect Company Since 2021.
            </h5>
            <div className="position-relative">
              <img
                className="mt-5 custom_width"
                src={about_image1}
                alt="about_image1"
              />
              <div className="about_box p-4 rounded-4 bg_theme manrope_FF">
                <div>
                  <h5 className="fs_47 fw-bold text-white mb-0">20+</h5>
                  <p className="mb-0 text-white fw-semibold">
                    Years of Experience
                  </p>
                </div>
                <div className="mt-3">
                  <h5 className="fs_47 fw-bold text-white mb-0">35K+</h5>
                  <p className="mb-0 text-white fw-semibold">Happy Clients</p>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col md={6} className="mt-5  mt-md-0 pt-4 pt-md-0">
            <img className="w-100" src={about_image} alt="about_image" />
            <div className="tabs-container mx-1 bg-gray align-items-center rounded-2 mt-5 row pb-3 pt-0">
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "history"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("history")}
              >
                <h6 className="mb-0 pt-3">Our History</h6>
              </div>
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "mission"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("mission")}
              >
                <h6 className="mb-0 pt-3">Our Mission</h6>
              </div>
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "vision"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("vision")}
              >
                <h6 className="mb-0 pt-3">Our Vision</h6>
              </div>
            </div>
            <div className="tab-content mx-1 mt-4">
              {activeTab === "history" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Welcome to Industrie, a leading industry innovator with a rich
                  history of excellence. With a passion for precision and a
                  commitment to quality, we have been empowering industries and
                  driving progress.
                </p>
              )}
              {activeTab === "mission" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Our mission is to provide high-quality products and services
                  that exceed customer expectations, foster innovation, and
                  drive sustainable growth in the industry.
                </p>
              )}
              {activeTab === "vision" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Our vision is to be a global leader in the industry,
                  recognized for our commitment to excellence, innovation, and
                  sustainable practices.
                </p>
              )}
            </div>
            <div className="additional-info mt-4">
              <div className="d-flex align-items-center gap-2">
                <img src={border_right_icon} alt="border_right_icon" />
                <p className="mb-0 manrope_FF fs_16 color_gray">
                  Quality Control System
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                <img src={border_right_icon} alt="border_right_icon" />
                <p className="mb-0 manrope_FF fs_16 color_gray">
                  Advanced Technology
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                <img src={border_right_icon} alt="border_right_icon" />
                <p className="mb-0 manrope_FF fs_16 color_gray">Expert Team</p>
              </div>
            </div>
            <Button className="px-4 mt-4 rounded-5 border-0 manrope_FF text-white fw-semibold py-2 bg_theme">
              Get in Touch <span>&rarr;</span>
            </Button>
          </Col> */}
          <Col md={6}>
            <img className="w-100" src={about_image} alt="about_image" />
            <h2 className="fw-bold mt-3 color_orange">Our Mission</h2>
            <p>
              Our mission is to create a reliable, transparent, and efficient
              marketplace for bitumen trading. We strive to enhance global
              connectivity between buyers and vendors by offering a platform
              that promotes clarity, convenience, and trust.
            </p>
            <div className="mt-4">
              <h4 className="fw-bold color_orange">For Buyers</h4>
              <h5 className=" fw-semibold">Effortless Purchasing of Bitumen</h5>
              <p>
                With BituConnect, purchasing bitumen is straightforward and
                efficient. Our platform streamlines the process from request to
                delivery, ensuring that buyers can secure the best deals on the
                market.
              </p>
            </div>
            <div className="mt-4">
              <h4 className="fw-bold color_orange">For Vendors</h4>
              <h5 className=" fw-semibold">
                Grow Your Business with Bituconnect
              </h5>
              <p>
                As a vendor on BituConnect, you have direct access to a global
                market of buyers looking for quality bitumen. The platform helps
                you to streamline communications, manage documents, and close
                deals quickly.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeAboutUsComponent;
