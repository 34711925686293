import React from "react";
import { Container } from "react-bootstrap";

const TermsOfService = () => {
  return (
    <div>
      <div className="bg_theme py-3">
        <Container>
          <h1 className=" text-white text-center fw-bold">Terms Of Service</h1>
        </Container>
      </div>
      <Container className="my-4">
        <h2 className="mt-5 fw-bold color_orange">Introduction</h2>
        <p className=" fs_17">
          Welcome to BituConnect. By accessing or using our platform, you agree
          to comply with and be bound by the following terms and conditions of
          use. Please read these terms carefully before using our services.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Account Registration</h2>
        <p className=" fs_17">
          To access certain features of BituConnect, you may be required to
          register an account. You are responsible for maintaining the
          confidentiality of your account credentials and for all activities
          that occur under your account.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Use of Services</h2>
        <p className=" fs_17">
          BituConnect provides tools for buyers and vendors to connect for the
          purchase of bitumen. You agree to use the platform for its intended
          purpose and to comply with all applicable laws and regulations.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Prohibited Activities</h2>
        <p className=" fs_17">
          Users are prohibited from engaging in any activities that violate the
          security or integrity of the platform. This includes, but is not
          limited to, unauthorized access, distributing harmful content, or
          attempting to interfere with the platform's functionality.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Termination of Use</h2>
        <p className=" fs_17">
          BituConnect reserves the right to terminate your access to the
          platform if you violate any terms of service or engage in activities
          that harm the platform or its users.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Limitation of Liability</h2>
        <p className=" fs_17">
          BituConnect shall not be held liable for any damages or losses
          resulting from the use of the platform. Our services are provided "as
          is" without any warranties of any kind.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Changes to Terms</h2>
        <p className=" fs_17">
          BituConnect reserves the right to update or modify these terms of
          service at any time. We encourage users to review the terms regularly
          to stay informed of any changes.
        </p>

        <h2 className="mt-5 fw-bold color_orange">Contact Us</h2>
        <p className=" fs_17">
          For further inquiries or assistance, feel free to reach out to our
          support team at <strong>support@bituconnect.com</strong> We're here to
          help you navigate every step of your bitumen trading experience.
        </p>
      </Container>
    </div>
  );
};

export default TermsOfService;
